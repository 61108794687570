<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="program_code" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :validate-error="errors[0]">
                            </program-selectbox>
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="tyyc_basic_field" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('tyyc_basic_fields')">
                            <parameter-selectbox v-model="formData.tyyc_basic_field" code="tyyc_basic_fields"
                                                 :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="updateForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
// Services

// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import CourseOutcome from "@/services/CourseOutcome";
import ProgramBasicFieldServices from "@/services/ProgramBasicFieldServices";

export default {
    components: {
        ParameterSelectbox,
        ProgramSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    props: {
        formId: {
            type: Number,
        }
    },
    data() {
        return {
            formData: {
                program_code:null,
                tyyc_basic_field:null
            },
        }
    },
    watch: {
        formId: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formId)
    },
    methods: {
        get(id) {
            ProgramBasicFieldServices.show(id)
                .then(response => {
                    let data = response.data.data;
                        this.formData = {
                            program_code:data.program_code,
                            tyyc_basic_field:data.tyyc_basic_field
                        }
                })
                .catch(e => {
                    this.showErrors(e)
                })
        },
        async updateForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid && this.formId) {
                    ProgramBasicFieldServices.update(this.formId, this.formData)
                        .then(response => {
                            this.$emit('updateFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
        }
    }
}
</script>
