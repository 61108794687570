<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12">
                    <ValidationProvider name="program_code" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('program')">
                            <program-selectbox v-model="formData.program_code"
                                               :validate-error="errors[0]">
                            </program-selectbox>
                        </b-form-group>
                    </ValidationProvider>

                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="tyyc_basic_field" v-slot="{ valid, errors }">
                        <b-form-group :label="$t('tyyc_basic_fields')">
                            <parameter-selectbox v-model="formData.tyyc_basic_field" code="tyyc_basic_fields"
                                                 :validate-error="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button variant="primary" @click="storeForm">
                    {{ $t('save') }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
// Other
import {ValidationProvider, ValidationObserver} from "vee-validate"
import ProgramBasicFieldServices from "@/services/ProgramBasicFieldServices";

export default {
    components: {
        ParameterSelectbox,
        StatusSelectbox,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {
              program_code:null,
              tyyc_basic_field:null
            },
        }
    },
    methods: {
        async storeForm() {
                const isValid = await this.$refs.formModalValidate.validate();
                if (isValid) {
                    ProgramBasicFieldServices.store(this.formData)
                        .then(response => {
                            this.$emit('createFormSuccess', true);
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.formModalValidate);
                        })
                        .finally(() => {
                            this.formLoading = false
                        })
                }
        }
    }
}
</script>
